import { graphql } from 'gatsby';
import React from 'react';

import Breadcrumbs from '@/components/common/Breadcrumbs';
import FilterableProductsList from '@/components/section/Product/FilterableProductsList';
import Seo from '@/components/seo';
import { mergeProductsAndPrices } from '@/utils/traversing';
import { ROUTE_NAMES } from '@/utils/url';

import * as style from './brand.module.scss';

const BrandTemplate = ({ data, pageContext, ...props }) => {
  const { location } = props;
  const { data: contextData } = pageContext;
  const { brand } = contextData;
  const breadcrumbs = [
    { url: `/${ROUTE_NAMES.BRANDS}`, name: 'Marcas' },
    { url: `/${ROUTE_NAMES.BRANDS}/${brand.uuid}`, name: brand.name },
  ];
  const products = mergeProductsAndPrices(data).allGoogleSpreadsheetProducts.edges.map(
    (e) => e.node,
  );
  return (
    <>
      <Seo
        title={brand.name}
        description={`Descubrí todos los productos ${brand.name} que Visciglio Audio & Instrumentos tiene para vos!`}
      />
      <section className={style.main}>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <FilterableProductsList
          title={brand.name}
          products={products}
          showBrandsFilter={false}
          location={location}
        />
      </section>
    </>
  );
};

export const pageQuery = graphql`
  query productsByBrand($brandUuid: String) {
    allGoogleSpreadsheetProducts(
      filter: { isPublished: { eq: "TRUE" }, brand: { eq: $brandUuid } }
    ) {
      edges {
        node {
          uuid
          title
          shortDescription
          brand
          isNew
          isFeatured
          isOffer
        }
      }
    }
  }
`;

export default BrandTemplate;
